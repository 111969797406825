export const firebase = {
  firebase: {
    apiKey: "AIzaSyBf0rd8p2lWFDfVrOgMmOvwfU6kbrduqfc",
    authDomain: "seftes-14e9d.firebaseapp.com",
    databaseURL: "https://seftes-14e9d-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "seftes-14e9d",
    storageBucket: "seftes-14e9d.appspot.com",
    messagingSenderId: "807596093597",
    appId: "1:807596093597:web:a5d94198c66007236fa474",
    measurementId: "G-M3XYCMFP13"
  }
}
